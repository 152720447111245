.quantity {
  width: 50px;
  height: 30px !important;
  margin-left: 5px;
}

.divider {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.extra-margin {
  margin-bottom: 15px;
}

.image-scale {
  max-width: 100%;
}

.plus-margin-right {
  margin-right: 10px;
}
.plus-margin-left {
  margin-left: 10px;
}

button.stripe-button-el {
  height: auto;
}

.footer {
  width: 100%;
  position: absolute;
}

button.stripe-mimic {
  height: auto;
  overflow: hidden;
  display: inline-block;
  visibility: visible !important;
  background-image: -webkit-linear-gradient(#28a0e5,#015e94);
  background-image: -moz-linear-gradient(#28a0e5,#015e94);
  background-image: -ms-linear-gradient(#28a0e5,#015e94);
  background-image: -o-linear-gradient(#28a0e5,#015e94);
  background-image: -webkit-linear-gradient(#28a0e5,#015e94);
  background-image: -moz-linear-gradient(#28a0e5,#015e94);
  background-image: -ms-linear-gradient(#28a0e5,#015e94);
  background-image: -o-linear-gradient(#28a0e5,#015e94);
  background-image: linear-gradient(#28a0e5,#015e94);
  -webkit-font-smoothing: antialiased;
  border: 0;
  padding: 1px;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  -ms-box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  -o-box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

.stripe-mimic span {
  display: block;
  position: relative;
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  background: #1275ff;
  background-image: -webkit-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -moz-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -ms-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -o-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -webkit-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -moz-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -ms-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: -o-linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  background-image: linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
  -ms-box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
  -o-box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
